<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="size"
    color="primary"
  >
    <CChartPie
      :datasets="defaultDatasets"
      :labels="['Success', 'Failure']"
    />
    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onCSV" color="danger" v-if="result.errors.length > 0">
        <CIcon name="cil-data-transfer-down" />&nbsp;CSV
      </CButton>
      <CButton @click="onClose" color="primary">
        Close
      </CButton>
    </template>
  </CModal>
</template>

<script>
import util from '@/utility'
import { CChartPie } from '@coreui/vue-chartjs'

export default {
  name: 'BulkResultModal',
  components: { CChartPie },
  props: {
    title: { type: String, default: 'Result' },
    size: { type: String, default: 'lg' },
    result: { type: Object, default: {header: [], items: [], current: 0, maxPage: 0, okCnt: 0, errors: []} },
  },
  computed: {
    defaultDatasets () {
      return [
        {
          backgroundColor: [
            '#2eb85c',
            '#e55353'
          ],
          data: [
            this.result.okCnt,
            this.result.errors.filter(e => !e.result).length
          ]
        }
      ]
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    open() {
      this.show = true;
    },
    onClose() {
      this.show = false;
    },
    onCSV() {
      let exportData = []
      for (let el of this.result.errors) {
        const rec = {}
        for (let i=0; i< this.result.header.length; i++) {
          rec[this.result.header[i]] = el.item[i];
        }
        rec.reason = el.reason;
        exportData.push(rec)
      }
      util.DownloadCSV(exportData, "add-err-$yyyy$mm$dd-$HH$MM$ss.csv");
    }
  }
}
</script>